import { QUERY_KEY } from "@/app/configs"
import { useQuery } from "@tanstack/react-query"
import TopupService from "../../services/TopupService"

const getListTopupFn = async () => {
  const data = await TopupService.getListGame()

  return data.data.data
}

const getTopupLaTransactionFn = async (transaction_id: string) => {
  const data = await TopupService.getLaTransaction(transaction_id)

  return data.data.data
}

const getGameTopupDetailFn = async (slug_name: string) => {
  const data = await TopupService.getInfoGameDetail(slug_name)

  return data.data.data
}

const getListGameServerTopup = async (params: {
  slug_name: string
  user_id: string
}) => {
  const data = await TopupService.getServerUserAndPlatformByUserId(params)

  return data.data.data
}

const getListGameRoleTopup = async (params: {
  slug_name: string
  server_id: string
  user_id: string
}) => {
  const data = await TopupService.getRoleByServerAndUserId(params)

  return data.data.data
}

export const checkPinFn = async () => {
  const data = await TopupService.checkPin()

  return data.data.data
}

export const useQueryGetListTopup = () => {
  return useQuery({
    queryKey: [QUERY_KEY.TOPUP_LIST_GAME],
    queryFn: () => getListTopupFn(),
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryGeGameTopupDetail = (slug_name: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.TOPUP_GAME_DETAIL, slug_name],
    queryFn: () => getGameTopupDetailFn(slug_name),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!slug_name
  })
}

export const useQueryGetListServerTopup = (params: {
  slug_name: string
  user_id: string
}) => {
  return useQuery({
    queryKey: [QUERY_KEY.TOPUP_GAME_SERVER, params.slug_name, params.user_id],
    queryFn: () => getListGameServerTopup(params),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!params.slug_name && !!params.user_id
  })
}

export const useQueryGetListRoleTopup = (params: {
  slug_name: string
  server_id: string
  user_id: string
}) => {
  return useQuery({
    queryKey: [
      QUERY_KEY.TOPUP_GAME_ROLE,
      params.slug_name,
      params.user_id,
      params.server_id
    ],
    queryFn: () => getListGameRoleTopup(params),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!params.slug_name && !!params.user_id && !!params.server_id
  })
}

export const useQueryGetLaTransaction = (transaction_id: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.TOPUP_LA_TRANSACTION],
    queryFn: () => getTopupLaTransactionFn(transaction_id),
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryCheckPin = () => {
  return useQuery({
    queryKey: [QUERY_KEY.TOPUP_CHECK_PIN],
    queryFn: () => checkPinFn(),
    refetchOnWindowFocus: false,
    retry: 0
  })
}
