import { IDoLaCharging } from "@/app/interfaces/ITopup"
import TopupService from "../../services/TopupService"
import { useMutation } from "@tanstack/react-query"
import { QUERY_KEY } from "@/app/configs"

const doLaChargingFn = (body: IDoLaCharging) => {
  return TopupService.doLaCharge(body)
}

export const useMutationDoLaCharging = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.TOPUP_DO_LA_CHARGING],
    mutationFn: doLaChargingFn
  })
}
