import { IMerchandise } from "@/app/interfaces"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { FC, useEffect } from "react"
import { useToast } from "../ui/use-toast"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import Link from "next/link"
import { removeVietnameseTones } from "@/app/helpers/function"

interface CardMerchandiseProps {
  product: IMerchandise
  scale?: boolean
}

export const CardMerchandise: FC<CardMerchandiseProps> = ({
  product,
  scale = false
}) => {
  const router = useRouter()
  const GAEventsTracker = useGAEventsTracker("Store_Merchandise")

  return (
    <Link
      href={{
        pathname: "/merchandise/detail/[id]",
        query: {
          id: `${removeVietnameseTones(product?.name ?? "")
            ?.replaceAll(" ", "-")
            ?.replaceAll("%", "-")
            ?.toLocaleLowerCase()}-${product.id}`
        }
      }}
    >
      <div
        className={`p-[8px] h-fit xs:p-[3px] xs:pb-[12px] bg-white dark:bg-white/10 rounded-[12px] border border-black/10 cursor-pointer transition duration-300 active:bg-bg-button active:border-bg-button dark:active:border-black/10 md:active:bg-white dark:md:active:bg-white/10 md:active:border-black/10 ${
          !scale
            ? "md:hover:shadow-[0px_3px_30px_0px_rgba(0,0,0,0.15)] scale-card"
            : "scale-card"
        }`}
        key={product.id}
        onClick={() => {
          GAEventsTracker("Store_Merchandise_View_item", product.name)
          router.push({
            pathname: "/merchandise/detail/[id]",
            query: {
              id: `${removeVietnameseTones(product?.name ?? "")
                ?.replaceAll(" ", "-")
                ?.replaceAll("%", "-")
                ?.toLocaleLowerCase()}-${product.id}`
            }
          })
        }}
      >
        <img
          src={product.images?.[0]}
          alt={product.name}
          className={`w-full object-cover aspect-square rounded-[12px] transition ease-in-out duration-300`}
          title={product?.name}
        />
        <div className="mt-[8px] px-[8px] py-[6px] xs:py-0">
          <p
            title={product.name}
            className="text-[14px] xs:text-[12px] text-left xs:leading-[16px] Nunito-600 leading-[19px] h-[38px] xs:h-[16px] xs:line-clamp-1"
          >
            {product.name}
          </p>
          <div className="pt-[4px] flex flex-row items-center gap-[4px] md:gap-[6px]">
            <p className="leading-[22px] text-main-01 Nunito-700 text-[16px] xs:text-[12px] xs:leading-[16px]">
              {(
                product?.price *
                (1 - product.sale_percent / 100)
              )?.toLocaleString()}{" "}
              đ
            </p>

            {/* <p className="text-[12px] xs:text-[10px] text-neutral-05 line-through dark:text-neutral-08 h-[14px]">
              {product?.is_sale === "yes"
                ? `${product?.price?.toLocaleString()} đ`
                : null}
            </p> */}

            {product?.is_sale === "yes" && (
              <div className="bg-[#FF8C8C33] dark:bg-main-red-03 px-[6px] h-[20px] xs:h-[16px] rounded-[2px] xs:text-[10px] xs:px-[2px] text-[12px] Nunito-600 text-[#FF6161] dark:text-main-white flex items-center justify-center">
                -{product.sale_percent}%
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  )
}
