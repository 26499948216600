import { GiftcodeList } from "./GiftcodeList"
import StoreBanner from "./StoreBanner"
import { StoreMerchandise } from "./StoreMerchandise"
import StoreProduct from "./StoreProduct"
import StoreTopupGame from "./StoreTopup"

const StoreContainer = ({}) => {
  return (
    <div className="max-w-[1062px] m-auto md:mt-[40px]">
      <StoreBanner />
      <GiftcodeList />
      <StoreMerchandise />
      <StoreProduct />
      <StoreTopupGame />
    </div>
  )
}

export default StoreContainer
