import { QUERY_KEY } from "@/app/configs"
import { useQuery } from "@tanstack/react-query"
import StoreService from "../../services/StoreServices"

const getListProductFn = async () => {
  const data = await StoreService.getListProduct()

  return data.data.data
}
export const useQueryGetListProduct = () => {
  return useQuery({
    queryKey: [QUERY_KEY.LIST_PRODUCT],
    queryFn: () => getListProductFn(),
    refetchOnWindowFocus: false,
    retry: 0
  })
}
