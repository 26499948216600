import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { IProduct } from "@/app/interfaces/IStore"
import { useQueryGetListProduct } from "@/app/services/query-fn/store"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import { useRouter } from "next/router"
import { FC, useMemo, useRef } from "react"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import AppotaCardImage from "public/img/store/appota_card.png"
import Image from "next/image"

SwiperCore.use([Pagination, Navigation])

const ProductItem: FC<{
  product: IProduct
}> = ({ product }) => {
  const router = useRouter()
  const GAEventsTracker = useGAEventsTracker("Store_card")
  return (
    <Link
      href={{
        pathname: "store/[id]",
        query: {
          id: product.product_code
        }
      }}
    >
      <div
        className="bg-white h-full dark:bg-white/10 rounded-[16px] xs:rounded-[8px] cursor-pointer p-[8px] xs:p-[3px] xs:pb-[7px] pb-[16px] border border-black/10 scale-card transition duration-300"
        style={{
          boxShadow: "4px 4px 15px 0px rgba(0, 0, 0, 0.04)"
        }}
        onClick={() => {
          GAEventsTracker("Store_View_Item", product.name)
        }}
      >
        <img
          src={product.icon}
          alt={product.name}
          title={product.name}
          className="w-full object-cover aspect-square rounded-[12px] xs:rounded-[8px] transition ease-in-out duration-300"
        />

        <p className="text-[14px] xs:text-[12px] xs:leading-[16px] Nunito-700 pt-[12px] xs:pt-[8px] text-center text-neutral-03 dark:text-main-white">
          {product.name}
        </p>
      </div>
    </Link>
  )
}

const StoreProduct = () => {
  const { t } = useTranslation()
  const { data } = useQueryGetListProduct()
  const router = useRouter()
  const navigationPrevRef = useRef<HTMLButtonElement>(null)
  const navigationNextRef = useRef<HTMLButtonElement>(null)

  const renderListGame = useMemo(() => {
    return (
      <div className="overflow-auto">
        <div className="hidden-scroll-bar ">
          <Swiper
            navigation={{
              prevEl: navigationPrevRef.current && navigationPrevRef.current,
              nextEl: navigationNextRef.current && navigationNextRef.current
            }}
            onInit={(swiper: any) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
              swiper.navigation.enable()
              swiper.navigation.update()
            }}
            slidesPerView={"auto"}
            breakpoints={{
              "576": {
                spaceBetween: 16
              }
            }}
            spaceBetween={8}
            className="w-full gap-[8px]"
          >
            {data?.map((product: IProduct, index: number) => {
              return (
                <SwiperSlide className="max-w-[120px] h-full flex" key={index}>
                  <ProductItem product={product} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    )
  }, [data])

  return (
    <div className="mt-[24px] md:mt-[32px] bg-[#F7931E] rounded-[16px] overflow-hidden">
      {/* <div className="flex justify-between items-center pb-[8px]">
        <h2 className="text-[20px] Nunito-700 xs:text-[16px]">
          {t("store.all_item")}
        </h2>
        <div className="xs:flex hidden items-center gap-[12px] ml-auto">
          <div className="flex items-center gap-[8px] ml-auto xs:hidden">
            <button
              className={`p-[2px] rounded-[10px] border border-main-01 cursor-pointer`}
              ref={navigationPrevRef}
            >
              <IconArrowLeft className="xs:w-[16px] xs:h-[16px]" />
            </button>
            <button
              className={`p-[2px] rounded-[10px] border border-main-01 cursor-pointer`}
              ref={navigationNextRef}
            >
              <IconArrowRight className="xs:w-[16px] xs:h-[16px]" />
            </button>
          </div>
        </div>
      </div>

      <div
        className={`w-full xs:hidden grid sd:grid-cols-5 gap-[16px] sm:grid-cols-3 md:grid-cols-4 grid-cols-2`}
      >
        {data?.map((product: IProduct, index: number) => {
          return <ProductItem product={product} key={index} />
        })}
      </div>
      <div className="hidden xs:block">{renderListGame}</div> */}

      <div
        className="h-[80px] md:h-[120px] cursor-pointer rounded-br-[56px] md:rounded-br-[80px] flex justify-between items-center pl-[18px] md:pl-[33px] pr-[25px] md:pr-[52px] active:opacity-60"
        style={{
          background: "linear-gradient(93deg, #008EFF 0%, #006EFF 79.79%)"
        }}
        onClick={() => {
          router.push(`/store/${process.env.NEXT_PUBLIC_APPOTA_CARD_END_POINT}`)
        }}
      >
        <Image
          src={AppotaCardImage}
          alt="appota card"
          width={300}
          height={44}
          className="w-[128px] h-[19px] md:w-[300px] md:h-[44px]"
        />

        <div className="px-[8px] md:px-[16px] h-[40px] md:h-[56px] border border-main-white rounded-[12px] md:rounded-[16px] bg-[#6E6E7826] flex gap-[10px] md:gap-[12px] items-center">
          <p className="text-[12px] md:text-[14px] Nunito-700 text-main-white">
            {t("store.buy_now")}
          </p>

          <div
            className="w-[24px] md:w-[32px] h-[24px] md:h-[32px] rounded-[10px] md:rounded-[10px] flex items-center justify-center"
            style={{
              background:
                "linear-gradient(129deg, #249DFF 8.41%, #85BAFF 100%)",
              boxShadow: "4px 4px 15px 0px rgba(0, 0, 0, 0.04)"
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="17"
              viewBox="0 0 18 17"
              fill="none"
              className="w-[13px] md:w-[18px]"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.1195 3.2087C16.5824 1.63338 15.0898 0.500366 13.3327 0.500366H4.66602C2.90886 0.500366 1.41633 1.63338 0.879156 3.2087L17.1195 3.2087ZM17.3325 4.4587L17.3327 4.50037V6.54203L0.666016 6.54203V4.50037L0.666228 4.4587L17.3325 4.4587ZM0.666016 7.79203L17.3327 7.79203V9.50037C17.3327 10.0527 16.885 10.5004 16.3327 10.5004H14.666C12.4569 10.5004 10.666 12.2912 10.666 14.5004C10.666 15.0527 10.2183 15.5004 9.66602 15.5004H4.66602C2.45688 15.5004 0.666016 13.7095 0.666016 11.5004V7.79203ZM4.83268 12.167C4.83268 12.6273 4.45959 13.0004 3.99935 13.0004C3.53911 13.0004 3.16602 12.6273 3.16602 12.167C3.16602 11.7068 3.53911 11.3337 3.99935 11.3337C4.45959 11.3337 4.83268 11.7068 4.83268 12.167ZM15.4577 12.167C15.4577 11.8219 15.1779 11.542 14.8327 11.542C14.4875 11.542 14.2077 11.8219 14.2077 12.167V13.2087H13.166C12.8208 13.2087 12.541 13.4885 12.541 13.8337C12.541 14.1789 12.8208 14.4587 13.166 14.4587H14.2077V15.5004C14.2077 15.8455 14.4875 16.1254 14.8327 16.1254C15.1779 16.1254 15.4577 15.8455 15.4577 15.5004V14.4587H16.4993C16.8445 14.4587 17.1243 14.1789 17.1243 13.8337C17.1243 13.4885 16.8445 13.2087 16.4993 13.2087L15.4577 13.2087V12.167Z"
                fill="#FAF8FF"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoreProduct
