import { IconEvent } from "@/app/components/icon-tsx/IconEvent"
import { IconPackage } from "@/app/components/icon-tsx/IconPackage"
import { IconPlayeeGold } from "@/app/components/icon-tsx/IconPlayeeGold"
import { IconTransaction } from "@/app/components/icon-tsx/IconTransaction"
import { isClient } from "@/app/configs"
import { AppContext, MobileContext } from "@/app/context"
import { useWindowSize } from "@/app/hooks"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { useQueryGetListGameBanner } from "@/app/services/query-fn/game"

import { useTranslation } from "next-i18next"
import Link from "next/link"
import { useRouter } from "next/router"
import { useContext } from "react"
import { Autoplay, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

const StoreBanner = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { isMobile, isHorizontalIpad } = useContext(MobileContext)
  const [width] = useWindowSize()
  const isNotTablet = width < 768
  const { userInfo } = useContext(AppContext)
  const GAEventsTracker = useGAEventsTracker("Store_card")
  const token = isClient ? window.localStorage.getItem("token") : null

  const { data: listGameBanner } = useQueryGetListGameBanner({
    type: "store"
  })

  const convertLink = (url: string) => {
    const link =
      url?.includes(process.env.NEXT_PUBLIC_EVENT_DOMAIN || "") && token
        ? `${url}?token=${token}`
        : url
    return link
  }

  const onAdditionalOpenBannerUrl = (banner: any) => {
    GAEventsTracker("Store_Banner Click", banner?.click_url)
  }

  // const listAction = [
  //   {
  //     icon: (
  //       <IconPlayeeGold
  //         className="w-[24px] h-[24px] md:w-[16px] md:h-[16px]"
  //         fill={isNotTablet ? "#7673E6" : ""}
  //         contentView={isNotTablet ? "#FAF8FF" : ""}
  //       />
  //     ),
  //     title: t("common.mission_center"),
  //     desc: t("common.mission_center"),
  //     link: "/reward-center?tab=mission-center"
  //   },
  //   {
  //     icon: (
  //       <IconTransaction
  //         className="w-[24px] h-[24px] md:w-[16px] md:h-[16px]"
  //         fill={isNotTablet ? "#7673E6" : ""}
  //       />
  //     ),
  //     title: t("profile.mobile_tab_transaction"),
  //     desc: t("profile.transaction_history"),
  //     link: `/user/${
  //       !!userInfo?.alias ? userInfo?.alias : userInfo?.userid
  //     }?tab=transaction-manager`
  //   },
  //   {
  //     icon: (
  //       <IconPackage
  //         className="w-[24px] h-[24px] md:w-[16px] md:h-[16px]"
  //         fill={isNotTablet ? "#7673E6" : ""}
  //       />
  //     ),
  //     title: t("common.inventory"),
  //     desc: t("common.inventory_desc"),
  //     link: "/reward-center?tab=package"
  //   },
  //   {
  //     icon: (
  //       <IconEvent
  //         className="w-[24px] h-[24px] md:w-[16px] md:h-[16px]"
  //         fill={isNotTablet ? "#7673E6" : ""}
  //       />
  //     ),
  //     title: t("event.event_title"),
  //     desc: t("event.in_progress"),
  //     link: "/news?tab=event"
  //   }
  // ]

  return (
    <div className="relative hidden-scroll-bar">
      <div className="relative md:mt-[45px] rounded-[15px]">
        {listGameBanner && listGameBanner?.length > 0 && (
          <Swiper
            modules={[Autoplay, Pagination]}
            slidesPerView={1}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false
            }}
          >
            {listGameBanner?.length
              ? listGameBanner.map((item: any, i: number) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="relative">
                        <Link
                          href={convertLink(item.click_url)}
                          target="_blank"
                          onClick={() => onAdditionalOpenBannerUrl(item)}
                        >
                          <div className="rounded-[15px] overflow-hidden relative">
                            <img
                              src={item?.banner}
                              alt={item.game_id}
                              className="w-[100%] aspect-[3/1] object-cover"
                            />
                          </div>
                        </Link>
                      </div>
                    </SwiperSlide>
                  )
                })
              : null}
          </Swiper>
        )}
      </div>

      {/* <div className="hidden absolute z-10 max-w-[670px] w-full h-[62px] md:flex items-center left-[50%] bottom-[-28px] translate-x-[-50%] p-[12px] bg-white dark:bg-dark-mode-box-2 rounded-[24px] shadow-[4px_4px_15px_0_rgba(0,0,0,0.04)]">
        {listAction?.map((item, index) => {
          return (
            <>
              <div
                key={item?.link}
                onClick={() => {
                  router.push(item?.link)
                }}
                className="flex-1 h-full cursor-pointer flex items-center justify-center gap-[8px] active:opacity-60"
              >
                <div className="w-[28px] h-[28px] rounded-[10px] bg-main-01 flex items-center justify-center">
                  {item?.icon}
                </div>
                <div className="">
                  <p className="text-[12px] Nunito-600 text-main-01 dark:text-main-white">
                    {item?.title}
                  </p>
                  <p className="text-[10px] Nunito-500 text-neutral-04 dark:text-neutral-06">
                    {item?.desc}
                  </p>
                </div>
              </div>
              {index !== 3 && (
                <div className="w-[1px] h-[20px] bg-black/10 dark:bg-white/10"></div>
              )}
            </>
          )
        })}
      </div>

      <div className="flex md:hidden justify-around mt-[12px]">
        {listAction?.map((item, index) => {
          return (
            <div
              key={item?.link}
              onClick={() => {
                router.push(item?.link)
              }}
              className="flex flex-col items-center"
            >
              {item?.icon}
              <p className="mt-[6px] text-neytral-04 dark:text-neutral-08 text-[14px] Nunito-500">
                {item?.title}
              </p>
            </div>
          )
        })}
      </div> */}
    </div>
  )
}

export default StoreBanner
