import { CardGiftcode } from "@/app/components/card-giftcode"
import { GC_TYPE } from "@/app/configs"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { IGiftcode } from "@/app/interfaces/IGiftcode"
import { useQueryGetListGiftcode } from "@/app/services/query-fn/giftcode/giftcode-query"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import { useRef } from "react"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

SwiperCore.use([Pagination, Navigation])

export const GiftcodeList = () => {
  const { t } = useTranslation()
  const { data } = useQueryGetListGiftcode({ style_list: "list-store" })
  const navigationPrevRef = useRef<HTMLButtonElement>(null)
  const navigationNextRef = useRef<HTMLButtonElement>(null)
  const GAEventsTracker = useGAEventsTracker("Giftcode")

  return data && data?.length > 0 ? (
    <div className="flex flex-col  mt-[24px] md:mt-[32px] pr-[-16px] ">
      <div className="flex justify-between items-center pb-[8px]">
        <h2 className="text-[20px] Nunito-700 xs:text-[16px]">Hot Giftcode</h2>
        <Link
          href={"/giftcode"}
          onClick={() => {
            GAEventsTracker("Store_giftcode_view")
          }}
          className="text-[16px] Nunito-500 text-main-01 xs:text-[12px]"
        >
          {t("common.view_more")}
        </Link>
      </div>
      <div className="">
        {data && data?.length > 0 && (
          <Swiper
            navigation={{
              prevEl: navigationPrevRef.current && navigationPrevRef.current,
              nextEl: navigationNextRef.current && navigationNextRef.current
            }}
            onInit={(swiper: any) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
              swiper.navigation.enable()
              swiper.navigation.update()
            }}
            slidesPerView={"auto"}
            spaceBetween={12}
            className="w-full overflow-scroll"
          >
            {data?.slice(0, 10)?.map((item: IGiftcode) => {
              return (
                <SwiperSlide
                  className="w-fit max-w-[346px] xs:max-w-[320px] h-full flex"
                  key={`${item?.id}-game-detail-giftcode`}
                >
                  <CardGiftcode item={item} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}
      </div>
    </div>
  ) : null
}
