import { zodResolver } from "@hookform/resolvers/zod"
import { i18n, Trans, useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react"
import { useForm } from "react-hook-form"
import * as z from "zod"

import { IconFacebook, IconGoogle } from "@/app/components/icon-tsx"
import { IconClearInput } from "@/app/components/icon-tsx/IconClearInput"
import { Button } from "@/app/components/ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger
} from "@/app/components/ui/dialog"
import { FloatingInput } from "@/app/components/ui/floating-input"
import { Form, FormControl, FormField } from "@/app/components/ui/form"
import { useToast } from "@/app/components/ui/use-toast"
import { isClient, OPEN_MODAL, QUERY_KEY } from "@/app/configs"
import {
  useMutationNormalLogin,
  useQueryGetUrlLoginFacebook,
  useQueryGetUrlLoginGoogle
} from "@/app/services/query-fn"
import { ChevronLeft, Eye, EyeOff } from "lucide-react"
import { useQueryClient } from "@tanstack/react-query"
import LogoBg from "@public/img/playee_full_text.png"
import LogoDark from "@/public/img/playee_full_text_dark.png"
import { AppContext } from "@/app/context"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"

type TLoginModal = {
  trigger?: React.ReactNode
}

const LoginFormSchema = z.object({
  username: z.string().min(1, {
    message: i18n?.t("message.required", { field: "Gamota Id" })
  }),
  password: z.string().min(1, {
    message: i18n?.t("message.required", { field: i18n?.t("common.password") })
  })
})

export type TLoginParams = z.infer<typeof LoginFormSchema>

export const LoginModal: React.FC<TLoginModal> = ({ trigger }) => {
  const router = useRouter()
  const { toast } = useToast()
  const { t } = useTranslation()
  const GAEventsTracker = useGAEventsTracker("Login")
  const GAEventsTrackerRegister = useGAEventsTracker("Register")
  const { themeApp } = useContext(AppContext)
  const { openModal, setOpenModal } = useContext(AppContext)

  const token = isClient ? window.localStorage.getItem("token") : null
  const secureOffPassword = useMemo(
    () => router?.query?.secure === "off",
    [router]
  )
  const queryClient = useQueryClient()
  const [focus, setFocus] = useState<keyof TLoginParams | undefined>()
  const loginForm = useForm<TLoginParams>({
    resolver: zodResolver(LoginFormSchema),
    defaultValues: {
      username: "",
      password: ""
    }
  })

  useEffect(() => {
    if (!!token && openModal === OPEN_MODAL.LOGIN_MODAL) {
      onRemoveParamAndNavigate(true)
    }

    if (!token && openModal === OPEN_MODAL.LOGIN_MODAL) {
      GAEventsTracker("Open_login_modal")
    }
  }, [token, openModal])

  const { mutate, isPending } = useMutationNormalLogin()
  const { refetch: refetchGetUrlLoginGoogle, isFetching: isFetchingGoogleUrl } =
    useQueryGetUrlLoginGoogle()

  const {
    refetch: refetchGetUrlLoginFacebook,
    isFetching: isFetchingFacebookUrl
  } = useQueryGetUrlLoginFacebook()

  const {
    handleSubmit,
    control,
    formState: { isValid },
    clearErrors,
    reset,
    watch,
    setValue
  } = loginForm

  const watchUsername = watch("username")
  const watchPassword = watch("password")

  const onRemoveParamAndNavigate = (success: boolean) => {
    if (
      success ||
      ![
        "/user/[id]",
        "/topup/[slug_name]",
        "/reward-center/rut-li-xi"
      ].includes(router?.pathname)
    ) {
      setOpenModal(null)
    } else {
      toast({
        variant: "error",
        title: t("message.need_login_to_view")
      })
    }
  }

  const onSubmitForm = (_data: TLoginParams) => {
    GAEventsTracker("Login_Normal")
    mutate(_data, {
      onSuccess(data) {
        const res = data?.data?.data
        if (!!res?.access_token && isClient) {
          localStorage.setItem("token", res?.access_token)
        }
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.PROFILE_INFO],
          refetchType: "active"
        })
        onLoginSuccess()
      },
      onError(error: any) {
        toast({
          variant: "error",
          title: error?.data?.message
        })
      }
    })
  }

  const onLoginSuccess = () => {
    // router.back()
    onRemoveParamAndNavigate(true)
    queryClient.invalidateQueries()
    clearErrors()
    reset()
  }

  const handleClose = () => {
    onRemoveParamAndNavigate(false)
    clearErrors()
    reset()
  }

  const onChangeSeePassword = (show: boolean) => {
    const { secure, ...currentQuery } = router.query
    if (!!show) {
      router.push({
        pathname: router.pathname,
        query: {
          ...currentQuery,
          secure: "off"
        }
      })
    } else {
      router.push({
        pathname: router.pathname,
        query: currentQuery
      })
    }
  }

  const onChangeFocus = (field: keyof TLoginParams) => {
    setFocus(field)
  }
  const onChangeBlur = (e: React.ChangeEvent) => {
    e.stopPropagation()
    setFocus(undefined)
  }

  const onResetField =
    (field: keyof TLoginParams) => (e: React.ChangeEvent) => {
      setValue(field, "")
    }

  const getURlGoogleAndRedirect = useCallback(() => {
    GAEventsTracker("Login_by_Google")
    refetchGetUrlLoginGoogle()
  }, [])

  const getURlFacebookAndRedirect = useCallback(() => {
    GAEventsTracker("Login_by_Facebook")
    refetchGetUrlLoginFacebook()
  }, [])

  const onNavigateToRegister = () => {
    GAEventsTrackerRegister("Register_Account")
    router.push("https://id.gamota.com/register")
  }
  const onNavigateToForgotPassword = () => {
    GAEventsTracker("ForgotPassword_Open")
    router.push("https://id.gamota.com/forgot_password")
  }
  return (
    <Dialog
      open={openModal === OPEN_MODAL.LOGIN_MODAL}
      onOpenChange={handleClose}
    >
      <DialogTrigger asChild>{trigger}</DialogTrigger>

      <DialogContent className="sm:rounded-[48px] md:max-w-[473px] py-[48px] px-[24px] md:px-[60px] dark:bg-bg-02 outline-none dark:border-0 h-[100vh]">
        {/* <DialogClose className="absolute transition-opacity rounded-sm md:hidden left-6 top-3 focus:outline-none disabled:pointer-events-none">
          <div className="w-[32px] h-[32px] rounded-[8px] flex items-center justify-center bg-main-01">
            <ChevronLeft color="#ffffff" />
          </div>
        </DialogClose> */}
        <div className="flex flex-col items-center justify-center w-full">
          <img
            src={themeApp === "dark" ? LogoDark.src : LogoBg.src}
            className="w-[234px] h-[81px]"
          />

          <div className="w-full mt-[32px]">
            <Form {...loginForm}>
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <FormField
                  control={control}
                  name="username"
                  render={({ field }) => (
                    <FormControl>
                      <>
                        <FloatingInput
                          placeholder="Gamota Id"
                          label={"Gamota Id"}
                          {...field}
                          className={`block rounded-2xl h-[52px] px-[24px] mb-4 dark:text-white inputDark ${
                            !!watchUsername ? "dark:bg-transparent" : ""
                          }`}
                          labelClassName={`${
                            !!watchUsername ? "dark:bg-bg-02" : ""
                          }`}
                          postfixIcon={
                            !!watch("username") && focus === "username" ? (
                              <IconClearInput
                                onMouseDown={onResetField("username")}
                              />
                            ) : null
                          }
                          onFocus={() => onChangeFocus("username")}
                          onBlur={onChangeBlur}
                        />
                      </>
                    </FormControl>
                  )}
                />
                <FormField
                  control={control}
                  name="password"
                  render={({ field }) => (
                    <FormControl>
                      <>
                        <FloatingInput
                          placeholder={t("common.password")}
                          type={secureOffPassword ? "text" : "password"}
                          label={t("common.password")}
                          security=""
                          {...field}
                          className={`rounded-2xl h-[52px] px-[24px] mb-4 dark:text-white inputDark ${
                            !!watchPassword ? "dark:bg-transparent" : ""
                          }`}
                          labelClassName={`${
                            !!watchPassword ? "dark:bg-bg-02" : ""
                          }`}
                          onFocus={() => onChangeFocus("password")}
                          onBlur={onChangeBlur}
                          postfixIcon={
                            !!watch("password") && focus === "password" ? (
                              <IconClearInput
                                onMouseDown={onResetField("password")}
                              />
                            ) : secureOffPassword ? (
                              <Eye
                                size={24}
                                color="#1C1E1E"
                                onClick={() => onChangeSeePassword(false)}
                              />
                            ) : (
                              <EyeOff
                                size={24}
                                color="#1C1E1E"
                                onClick={() => onChangeSeePassword(true)}
                              />
                            )
                          }
                        />
                      </>
                    </FormControl>
                  )}
                />
                <p
                  className="flex mt-0 ml-auto text-sm cursor-pointer Nunito-500 w-fit text-main-01 md:mb-4"
                  onClick={onNavigateToForgotPassword}
                >
                  {t("common.forgot_password")}
                </p>

                <div className="flex flex-col items-center w-full">
                  <div className="w-full">
                    <Button
                      type="submit"
                      variant="none"
                      disabled={isPending || !isValid}
                      className={`w-full Nunito-700 text-sm rounded-2xl mt-[32px] md:mt-0 px-[24px] py-[13px] ${
                        isPending || !isValid
                          ? "bg-button text-main-01 dark:bg-white/10 dark:text-white"
                          : "text-white bg-main-01"
                      }`}
                    >
                      {t("common.login")}
                    </Button>
                    <p className="Nunito-400 text-[#706E7E] text-sm mt-4 text-center">
                      Chưa có tài khoản{" "}
                      <span
                        className="text-sm cursor-pointer Nunito-400 text-main-01"
                        onClick={onNavigateToRegister}
                      >
                        {t("common.register")}
                      </span>
                    </p>
                  </div>

                  <div className="w-full">
                    <div className="w-full flex gap-4 mb-4 mt-[48px] md:mt-4 items-center">
                      <div className="h-[1px] w-full bg-black/[.1]" />
                      <span className="text-[#706E7E] Nunito-400 text-sm">
                        {t("common.or")}
                      </span>
                      <div className="h-[1px] w-full bg-black/[.1]" />
                    </div>

                    <div className="w-full grid grid-cols-2 gap-[24px]">
                      <Button
                        variant="link"
                        disabled={isFetchingGoogleUrl}
                        className="rounded-2xl h-[48px] bg-white border border-black/10 dark:border-0 dark:bg-white/10"
                        onClick={getURlGoogleAndRedirect}
                      >
                        <IconGoogle />
                      </Button>
                      <Button
                        disabled={isFetchingFacebookUrl}
                        variant="link"
                        className="rounded-2xl h-[48px] bg-[#1877F2]"
                        onClick={getURlFacebookAndRedirect}
                      >
                        <IconFacebook />
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </Form>

            <div className="mt-8 text-xs text-center Nunito-500 text-text-02">
              <Trans
                i18nKey={t("common.login_desc")}
                components={[
                  <span
                    className="text-xs cursor-pointer text-main-01 Nunito-700"
                    onClick={() => {
                      router.push("/term-of-services")
                    }}
                    key={0}
                  ></span>,
                  <span
                    className="text-xs cursor-pointer text-main-01 Nunito-700"
                    onClick={() => {
                      router.push("/privacy-policy")
                    }}
                    key={1}
                  ></span>
                ]}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
