import { CardMerchandise } from "@/app/components/card-merchandise"
import { IconArrowLeft } from "@/app/components/icon-tsx/IconArrowLeft"
import { IconArrowRight } from "@/app/components/icon-tsx/IconArrowRight"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { IMerchandise } from "@/app/interfaces"
import { useQueryGetListMerchandise } from "@/app/services/query-fn/merchandise/merchandise-query"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import { useRouter } from "next/router"
import { useRef } from "react"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

SwiperCore.use([Pagination, Navigation])

export const StoreMerchandise = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const navigationPrevRef = useRef<HTMLButtonElement>(null)
  const navigationNextRef = useRef<HTMLButtonElement>(null)
  const GAEventsTracker = useGAEventsTracker("Store_Merchandise")

  const { data: listMerchandise } = useQueryGetListMerchandise({
    limit: 10
  })

  return listMerchandise?.data?.length > 0 ? (
    <div className="mt-[24px] md:mt-[32px] flex flex-col">
      <div className="flex justify-between items-center pb-[8px]">
        <h2 className="text-[20px] Nunito-700 xs:text-[16px]">Merchandise</h2>
        <div className="flex items-center gap-[8px] ml-auto xs:hidden">
          <button
            className={`p-[2px] rounded-[10px] border border-main-01 cursor-pointer`}
            ref={navigationPrevRef}
          >
            <IconArrowLeft className="xs:w-[16px] xs:h-[16px]" />
          </button>
          <button
            className={`p-[2px] rounded-[10px] border border-main-01 cursor-pointer`}
            ref={navigationNextRef}
          >
            <IconArrowRight className="xs:w-[16px] xs:h-[16px]" />
          </button>
        </div>
        <Link
          href={"/merchandise"}
          onClick={() => {
            GAEventsTracker("Merchandise_view_tab")
          }}
          className="text-[16px] Nunito-500 text-main-01 xs:text-[12px] hidden xs:block"
        >
          {t("common.view_more")}
        </Link>
      </div>
      <div className="hidden-scroll-bar">
        <Swiper
          navigation={{
            prevEl: navigationPrevRef.current && navigationPrevRef.current,
            nextEl: navigationNextRef.current && navigationNextRef.current
          }}
          onInit={(swiper: any) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current
            swiper.params.navigation.nextEl = navigationNextRef.current
            swiper.navigation.enable()
            swiper.navigation.update()
          }}
          slidesPerView={"auto"}
          breakpoints={{
            "576": {
              spaceBetween: 16
            }
          }}
          spaceBetween={8}
          className="w-full gap-[8px] pt-[8px] md:pt-[16px] flex"
          style={{
            paddingBottom: "9px"
          }}
        >
          {listMerchandise?.data?.map(
            (product: IMerchandise, index: number) => {
              return (
                <SwiperSlide
                  className="w-full sd:max-w-[199px] max-w-[174.5px] xs:max-w-[120px] !h-full flex"
                  key={index}
                >
                  <CardMerchandise product={product} scale />
                </SwiperSlide>
              )
            }
          )}
        </Swiper>
      </div>

      <div
        className="flex xs:hidden gap-[8px] w-fit items-center cursor-pointer text-main-01 Nunito-500 text-[16px] xs:text-[14px] active:opacity-60 ml-auto mr-0"
        onClick={() => {
          GAEventsTracker("Merchandise_view_tab")
          router.push("/merchandise")
        }}
      >
        {t("common.view_all")}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path
            d="M14 16.1L18 12.1M18 12.1L14 8.09998M18 12.1L6 12.1"
            stroke="#7673E6"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  ) : null
}
